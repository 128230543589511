import { useEffect, useState } from "react";
import { BsTerminalFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { DiMongodb } from "react-icons/di";
import { FaAws, FaReact } from "react-icons/fa";
import { GrMysql } from "react-icons/gr";
import { IoLogoJavascript } from "react-icons/io";
import { Icons } from "../models/Icons";
import { Project, ProjectsResponse } from "../models/Projects";

import Plot from "./Chart";

import {
  SiCelery,
  SiDjango,
  SiFastapi,
  SiFlask,
  SiPython,
} from "react-icons/si";
import { toast } from "sonner";
import { api } from "../api";
import Loading from "./Loading";

const Projects = () => {
  const { t } = useTranslation();
  const [projects, setProjects] = useState<Project[]>([]);
  const [projectStats, setProjectStats] = useState({});

  const [projectsLoaded, setProjectsLoaded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isGithubLoading, setIsGithubLoading] = useState<boolean>(false);

  const languageIcons: Icons = {
    python: <SiPython />,
    javascript: <IoLogoJavascript />,
    bash: <BsTerminalFill />,
    django: <SiDjango />,
    sql: <GrMysql />,
    celery: <SiCelery />,
    flask: <SiFlask />,
    fastapi: <SiFastapi />,
    react: <FaReact />,
    nosql: <DiMongodb />,
    aws: <FaAws />,
  };

  const fetchRepositories = async () => {
    setIsGithubLoading(true);
    const response = await api.get("/projects-statistics");
    setProjectStats({ data: response.data.statistics });
  };

  const getProjects = async () => {
    setIsLoading(true);
    const updateProjectStates = (projects: Project[]) => {
      if (projects.length > 0) {
        setProjects(projects);
        setProjectsLoaded(true);
      } else {
        setProjectsLoaded(false);
      }
    };

    await api
      .get<ProjectsResponse>("/projects")
      .then((response) => {
        if (response.status === 200) {
          updateProjectStates(response.data.projects);
        } else {
          updateProjectStates([]);
        }
      })
      .catch((error) => {
        toast.error(`Error Occured: ${error.message}`);
      });
  };

  const handleOnClick = (projectUrl: string) => {
    window.open(projectUrl, "_blank");
  };

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        getProjects().then(() => {
          setIsLoading(false);
        }),
        fetchRepositories().then(() => {
          setIsGithubLoading(false);
        }),
      ]);
    };

    fetchData();
  }, []);

  if (projectsLoaded === false) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <h1 className="text-white text-xl font-bold">Fetch failed</h1>
        <a
          href="https://github.com/Aradhya-Tripathi"
          target="_blank"
          rel="noreferrer"
          className="text-white text-xl font-bold underline mt-2"
        >
          Github
        </a>
      </div>
    );
  }

  return (
    <div className="flex flex-col lg:flex-row items-center justify-center h-screen w-screen">
      <div className="flex flex-col bg-off-white h-auto max-h-[50%] lg:max-h-[75%] md:max-h-[75%] w-3/4 text-center overflow-auto rounded-lg lg:ml-5 mt-5">
        {isLoading ? (
          <Loading color="black" />
        ) : (
          projects.map((project) => {
            return (
              <div
                key={project.id}
                className="h-auto hover:bg-card-color border-b cursor-pointer p-5"
                onClick={() => {
                  handleOnClick(project.link);
                }}
              >
                <h1 className="text-xl font-bold">{project.name}</h1>
                <h2 className="text-lg font-semibold">
                  {t(project.description)}
                </h2>
                <div className="flex justify-center">
                  {project.tags.split(",").map((tag, index) => {
                    return (
                      <h3
                        key={index}
                        className="mx-2 mb-2 font-normal  rounded"
                      >
                        {tag}
                      </h3>
                    );
                  })}
                </div>
                <div className="flex justify-center">
                  {project.technologies.split(",").map((technology, index) => {
                    return (
                      <h3 key={index} className="mx-2">
                        {languageIcons[technology.trim()]}
                      </h3>
                    );
                  })}
                </div>
              </div>
            );
          })
        )}
      </div>
      <div className="flex lg:ml-5 lg:mr-5 mb-5 lg:w-[25%] w-3/4 h-[25%] mt-5 rounded bg-off-white justify-center items-center">
        {isGithubLoading ? (
          <Loading />
        ) : (
          <div className="flex items-center justify-center h-[80%] w-full">
            <Plot data={projectStats} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Projects;
