import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { NotFoundProps } from "../models/NotFoundprops";

const NotFound = ({ currentLocation }: NotFoundProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate("/");
      toast.success("Back home!");
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [navigate]);

  return (
    <div className="flex flex-col h-screen items-center justify-center">
      <h1 className="text-center text-white text-3xl text-bold">
        404 - Not Found
      </h1>
      <h1 className="text-center text-white text-3xl text-bold">
        {currentLocation}
      </h1>
      <p className="text-white text-center">Redirecting...</p>
    </div>
  );
};

export default NotFound;
