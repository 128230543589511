import { NavBar } from "./components/NavBar";
import { Home } from "./components/Home";
import Projects from "./components/Projects";
import NotFound from "./components/NotFound";
import ContactMe from "./components/ContactMe";

import { Routes, Route, useLocation } from "react-router-dom";
import { useState } from "react";
import { Toaster } from "sonner";

const App = () => {
  const [nav, setNav] = useState<boolean>(false);
  const location = useLocation();

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-gray-800 to-gray-900">
      <NavBar nav={nav} setNav={setNav} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contact" element={<ContactMe />}></Route>
        <Route
          path="*"
          element={<NotFound currentLocation={location.pathname} />}
        />
      </Routes>
      <Toaster />
    </div>
  );
};

export default App;
