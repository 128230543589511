import { BarElement, CategoryScale, Chart, LinearScale } from "chart.js";
import { Bar } from "react-chartjs-2";
import { ChartProps } from "../models/ChartProps.d";

Chart.register(CategoryScale);
Chart.register(LinearScale);
Chart.register(BarElement);

const Plot = ({ data }: ChartProps) => {
  const chartData = {
    labels: Object.keys(data.data.techs),
    datasets: [
      {
        label: "Languages / Framework",
        data: Object.values(data.data.techs),
        backgroundColor: "lightblue",
        hoverBackgroundColor: "gray",
      },
    ],
  };

  return (
    <Bar
      data={chartData}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: 5,
        },
        scales: {
          y: {
            display: false,
          },
          x: {
            display: true,
          },
        },
      }}
    />
  );
};

export default Plot;
