import { GiHamburgerMenu } from "react-icons/gi";
import { IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { baseURL } from "../api";
import { NavEelement } from "../models/NavElements";
import { NavProps } from "../models/Navprops";

export const NavBar = ({ nav, setNav }: NavProps) => {
  const { t } = useTranslation();

  const navElements: NavEelement[] = [
    { name: t("Home"), url: "/" },
    { name: t("Projects"), url: "/projects" },
    { name: t("Contact"), url: "/contact" },
    {
      name: t("Resume"),
      url: "https://like-wave-992.notion.site/CV-Aradhya-Tripathi-0b98b2c95e7540cc897b71ef9ce15645?pvs=4",
    },
    { name: t("Admin"), url: baseURL + "/admin/" },
  ];

  return (
    <nav className="h-auto flex items-center justify-between">
      <h1 className="text-white text-2xl font-bold ml-3">Aradhya Tripathi</h1>
      <ul className="flex">
        {navElements.map((element) => {
          return (
            <li
              className="hidden md:block text-white text-2xl font-normal mr-3 cursor-pointer hover:text-slate-200 duration-75"
              key={element.name}
            >
              <Link to={element.url} target="_self">
                {element.name}
              </Link>
            </li>
          );
        })}
      </ul>
      <div
        onClick={() => {
          setNav(!nav);
        }}
        className="text-white md:hidden mr-5"
      >
        {nav ? (
          <IoClose size={30} className="cursor-pointer" />
        ) : (
          <GiHamburgerMenu size={30} className="cursor-pointer" />
        )}
      </div>

      {/* <div
        className={`flex items-center justify-center h-[50%] w-[50%] rounded-lg bg-black absolute top-[25%] left-[25%] ${
          !showlng ? "hidden" : ""
        }`}
      >
        <h1 className=" text-3xl bg-white text-black">
          Implement to show options else might just drop it and simply use
          browser language detector
        </h1>
      </div> */}

      <ul
        className={`flex flex-col h-screen w-full items-center justify-center absolute top-12 left-0 bg-gradient-to-b from-gray-800 to-gray-900 ${
          !nav ? "hidden" : ""
        }`}
      >
        {navElements.map((element) => {
          return (
            <li className="text-white text-2xl mb-10 hover:text-slate-50 duration-75">
              <Link
                to={element.url}
                target="_self"
                onClick={() => {
                  setNav(!nav);
                }}
              >
                {element.name}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
