import { useEffect, useState } from "react";
import { toast } from "sonner";
import { api } from "../api";
import { useNavigate } from "react-router-dom";

const ContactMe = () => {
  const [email, setEmail] = useState("");
  const [query, setQuery] = useState("");
  const [hasFilledEmail, setHasFilledEmail] = useState(false);
  const [hasFilledQuery, setHasFilledQuery] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    console.log(email, query);
    await api
      .post("/query", {
        email: email,
        query: query,
      })
      .then((response) => {
        if (response.status === 201) {
          toast.success("Query sent!");
        } else {
          toast.error(`Error occured ${response.status}`);
        }
      })
      .catch((err) => {
        toast.error(`Something went wrong!: ${err.message}`);
      })
      .finally(() => {
        setEmail("");
        setQuery("");
      });
  };

  useEffect(() => {
    if (hasFilledEmail && hasFilledQuery) {
      setTimeout(() => navigate("/"), 1000);
    }
  }, [hasFilledEmail, hasFilledQuery, navigate]);

  return (
    <div className="h-screen flex flex-col justify-center items-center">
      <form className="flex flex-col items-center justify-center rounded-lg h-3/4 w-full md:w-1/2 md:h-3/4 lg:h-3/4">
        {!hasFilledEmail && (
          <>
            <h1 className="text-white text-xl mb-3">
              What is your email address?
            </h1>
            <input
              type="text"
              placeholder="someone@xyz.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="placeholder:italic  focus:outline-none mt-5 bg-transparent w-3/4 border-b-2 border-b-white text-white"
            />

            <button
              className="text-black mt-10 bg-white rounded border border-white px-5"
              value={email}
              onClick={() => {
                setHasFilledEmail(true);
              }}
            >
              Next
            </button>
          </>
        )}

        {hasFilledEmail && !hasFilledQuery && (
          <>
            <h1 className="text-white text-xl mb-3">Enter your text</h1>
            <input
              type="text"
              placeholder="write something..."
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              className="placeholder:italic focus:outline-none mt-5 bg-transparent w-3/4 border-b-2 border-b-white text-white"
            />
            <button
              className="text-black mt-10 bg-white rounded border border-white px-5"
              value={email}
              onClick={() => {
                setHasFilledQuery(true);
                handleSubmit();
              }}
            >
              Done
            </button>
          </>
        )}

        {hasFilledEmail && hasFilledQuery && (
          <h1 className="text-white text-2xl mt-5">All done!</h1>
        )}
      </form>
    </div>
  );
};

export default ContactMe;
