import { useEffect, useState } from "react";
import { FaGithubSquare, FaLinkedinIn } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import Loading from "./Loading";
import { useTranslation } from "react-i18next";

export const Home = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { t } = useTranslation();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsLoading(false);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, []);

  if (isLoading === true) {
    return <Loading color="white" />;
  }

  return (
    <div className="flex w-full h-screen items-center justify-center">
      <div className="hidden lg:block">
        <a
          href="https://www.linkedin.com/in/aradhya-tripathi51/"
          target="_blank"
          rel="noreferrer"
          className="text-white cursor-pointer mr-4"
        >
          <FaLinkedinIn
            size={30}
            className="hover:text-gray-300 hover:ml-[10px] duration-300"
          ></FaLinkedinIn>
        </a>
        <a
          href="https://github.com/Aradhya-Tripathi"
          target="_blank"
          rel="noreferrer"
          className="text-white cursor-pointer mr-4"
        >
          <FaGithubSquare
            size={30}
            className="hover:text-gray-300 hover:ml-[10px] duration-300"
          ></FaGithubSquare>
        </a>

        <a
          href="mailto:arad.tripathi@gmail.com"
          className="text-white cursor-pointe"
        >
          <MdOutlineMail
            size={30}
            className="hover:text-gray-300 hover:ml-[10px] duration-300"
          ></MdOutlineMail>
        </a>
      </div>

      <div className="flex flex-col flex-grow items-center justify-center md:flex-row">
        <div className="flex flex-col items-center justify-center">
          <h1 className="text-white text-4xl text-center font-bold w-auto md:w-96">
            {t("I am almost a fullstack developer")}
          </h1>
          <h2 className="text-white text-center text-2xl w-auto md:w-96">
            {t(
              "Proficient in python; learning javascript and frontend frameworks to go along with it."
            )}
          </h2>
          <div className="h-8 flex w-28 mt-5 justify-between items-center lg:hidden">
            <a
              href="https://www.linkedin.com/in/aradhya-tripathi51/"
              target="_blank"
              rel="noreferrer"
              className="text-white cursor-pointer hover:text-gray-300 mr-4"
            >
              <FaLinkedinIn size={30}></FaLinkedinIn>
            </a>
            <a
              href="https://github.com/Aradhya-Tripathi"
              target="_blank"
              rel="noreferrer"
              className="text-white cursor-pointer hover:text-gray-300 mr-4"
            >
              <FaGithubSquare size={30}></FaGithubSquare>
            </a>

            <a
              href="mailto:arad.tripathi@gmail.com"
              className="text-white cursor-pointer hover:text-gray-300"
            >
              <MdOutlineMail size={30}></MdOutlineMail>
            </a>
          </div>
        </div>
        <img
          src="./Me.jpeg"
          alt={t("My-Image")}
          className="rounded-2xl mt-5 md:mt-0"
        />
      </div>
    </div>
  );
};
