import React from "react";
import PuffLoader from "react-spinners/PuffLoader";

interface LoadingProps {
  color?: string;
}

const Loading = ({ color = "#000000" }: LoadingProps) => {
  return (
    <div className="flex h-screen items-center justify-center">
      <PuffLoader color={color} size={200} />
    </div>
  );
};

export default Loading;
